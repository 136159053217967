import * as firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyD3xj40gs_JEy9-73q8VZxTrutGIpad9zs",
    authDomain: "project-react-58ef6.firebaseapp.com",
    databaseURL: "https://project-react-58ef6.firebaseio.com",
    projectId: "project-react-58ef6",
    storageBucket: "project-react-58ef6.appspot.com",
    messagingSenderId: "468441591979",
    appId: "1:468441591979:web:f37c47decb36f13f8bc7ee"
};

const fire = firebase.initializeApp(firebaseConfig);


export default fire;